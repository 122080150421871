<template>
	<section class="section">
		<div>
			<div class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
				<div class="bg-white overflow-hidden shadow rounded-lg cursor-pointer">
					<div class="px-4 py-5 sm:p-6">
						<div class="flex items-center">
							<div class="flex-shrink-0 bg-green-500 text-white text-sm rounded-md p-3">
								<!-- <svg class="h-6 w-6 text-white" fill="none" viewbox="0 0 24 24" stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"></path>
								</svg> -->
								{{ accessibilityPagePct }}%
							</div>
							<div class="ml-5 w-0 flex-1">
								<dl>
									<dd class="flex flex-col items-baseline">
										<div class="text-lg leading-8 font-semibold text-gray-900">Success</div>
										<dt class="text-sm leading-5 font-medium text-gray-500 truncate">{{ accessibilityPagePassCount }} pass, {{ accessibilityPageFailCount }} fail</dt>
									</dd>
								</dl>
							</div>
						</div>
					</div>
				</div>
				<div class="bg-white overflow-hidden shadow rounded-lg cursor-pointer">
					<div class="px-4 py-5 sm:p-6">
						<div class="flex items-center">
							<div class="flex-shrink-0 bg-red-500 text-white text-sm rounded-md p-3">
								<!-- <svg class="h-6 w-6 text-white" fill="none" viewbox="0 0 24 24" stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"></path>
								</svg> -->
								{{ accessibilityErrorsCount }}
							</div>
							<div class="ml-5 w-0 flex-1">
								<dl>
									<dd class="flex items-baseline">
										<div class="text-lg leading-8 font-semibold text-gray-900">Errors</div>
										<dt class="text-sm leading-5 font-medium text-gray-500 truncate">&nbsp;</dt>
									</dd>
								</dl>
							</div>
						</div>
					</div>
				</div>
				<div class="bg-white overflow-hidden shadow rounded-lg cursor-pointer">
					<div class="px-4 py-5 sm:p-6">
						<div class="flex items-center">
							<div class="flex-shrink-0 bg-orange-500 text-white text-sm rounded-md p-3">
								<!-- <svg class="h-6 w-6 text-white" fill="none" viewbox="0 0 24 24" stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76"></path>
								</svg> -->
								{{ accessibilityWarningsCount }}
							</div>
							<div class="ml-5 w-0 flex-1">
								<dl>
									<dd class="flex items-baseline">
										<div class="text-lg leading-8 font-semibold text-gray-900">Warnings</div>
										<dt class="text-sm leading-5 font-medium text-gray-500 truncate">&nbsp;</dt>
									</dd>
								</dl>
							</div>
						</div>
					</div>
				</div>
				<div class="bg-white overflow-hidden shadow rounded-lg cursor-pointer">
					<div class="px-4 py-5 sm:p-6">
						<div class="flex items-center">
							<div class="flex-shrink-0 bg-blue-500 text-white text-sm rounded-md p-3">
								<!-- <svg class="h-6 w-6 text-white" fill="none" viewbox="0 0 24 24" stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"></path>
								</svg> -->
								{{ accessibilityInfoCount }}
							</div>
							<div class="ml-5 w-0 flex-1">
								<dl>
									<dd class="flex items-baseline">
										<div class="text-lg leading-8 font-semibold text-gray-900">Info</div>
										<dt class="text-sm leading-5 font-medium text-gray-500 truncate">&nbsp;</dt>
									</dd>
								</dl>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<accessibility-table />
		<accessibility-success v-if="!accessibilityErrors.length && false" />
	</section>
</template>

<script>
	export default {
		props: {
			accessibility: {
				type: Object
			}
		},
		data() {
			return {
				selectedTab: 'errors'
			}
		},
		methods: {
			
		},
		computed: {
			accessibilityResults() {
				return this.accessibility?.results
			},
			accessibilityAllCount() {
				return this.accessibilityErrorsCount + this.accessibilityWarningsCount + this.accessibilityInfoCount
			},
			accessibilityErrorsCount() {
				return this.accessibility?.errors
			},
			accessibilityWarningsCount() {
				return this.accessibility?.warnings
			},
			accessibilityInfoCount() {
				return this.accessibility?.infos
			},
			accessibilityPageCount() {
				return this.accessibility?.numPages
			},
			accessibilityPageFailCount() {
				return this.accessibility?.numPagesFail
			},
			accessibilityPagePassCount() {
				return this.accessibility?.numPagesPass
			},
			accessibilityPagePct() {
				return this.accessibility?.numPagesPass / this.accessibility?.numPages
			},
			accessibilityErrors() {
				const results = this.accessibilityResults

				if(!results) {
					return []
				}

				let output = []

				for(let r in results) {
					let result = results[r]

					if(result.type == 'error') {
						result.url = r
						output.push(result)
					}
				}

				return output
			},
			accessibilityWarnings() {
				const results = this.accessibilityResults

				if(!results) {
					return []
				}

				let output = []

				for(let r in results) {
					let result = results[r]

					if(result.type == 'warning') {
						result.url = r
						output.push(result)
					}
				}

				return output
			},
			accessibilityInfo() {
				const results = this.accessibilityResults

				if(!results) {
					return []
				}

				let output = []

				for(let r in results) {
					let result = results[r]

					if(result.type == 'info') {
						result.url = r
						output.push(result)
					}
				}

				return output
			}
		}
	};
</script>
