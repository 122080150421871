<template>
	<div>
		<quill-editor
			v-model="content"
			ref="editor"
			:options="options"
			@change="onChange"
		/>
		<textarea
			:name="name"
			v-model="content"
			hidden
		></textarea>
	</div>
</template>

<script>
	export default {
		props: {
			name: {
				type: String,
				required: true
			},
			placeholder: String,
			value: String
		},
	    data() {
	    	return {
	    		options: {
	    			placeholder: this.placeholder
	    		},
	    		content: this.value
	    	}
	    },
		watch: {
	        value(value) {
	        	this.content = value;
	        },
	        // content(content) {
	        // 	this.$emit('input', content);
	        // }
	    },
	    methods: {
	        onChange() {
	            this.$emit('input', this.content)
	        }
	    },
	}
</script>