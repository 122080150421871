<template>
	<tr>
		<td class="border-t px-2 py-2">{{ check.url }}</td>
		<td class="border-t px-2 py-2">{{ check.message }}</td>
		<td class="border-t px-2 py-2">{{ check.context }}</td>
		<td class="border-t px-2 py-2">{{ check.recommendations }}</td>
		<td class="border-t px-2 py-2 cursor-pointer text-blue-800" v-on:click="markDismissed(check.id)">Dismiss</td>
	</tr>
</template>

<script>
	export default {
		props: {
			check: {
				type: Object
			}
		},
		data() {
			return {
				
			}
		},
		methods: {
			markDismissed(id) {
				this.$emit('mark-dismissed', id)
			}
		},
		computed: {

		},
		mounted() {
		}
	};
</script>
<style lang="scss" scoped>
</style>