<template>
  <modal name="add-property-modal">
    <div slot="modal-content">
      <component :is="'wizard-step' + step" v-on:nextstep="nextStep" v-on:prevstep="prevStep" />
    </div>
  </modal>
</template>

<script>
export default {
  data () {
    return {
      step: 1
    }
  },
  methods: {
    nextStep (step = null) {
      if (step) {
        this.step = null
      } else {
        this.step++
      }
    },
    prevStep (step = null) {
      if (step) {
        this.step = null
      } else {
        this.step--
      }
    }
  }
}
</script>
