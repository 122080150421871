<template>
	<modal name="login-modal">
		<template v-slot:modal-title><h1>Add Ticket</h1></template>
		<template v-slot:modal-content>
			<div class="min-h-screen bg-gray-50 bg-opacity-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
				<div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
					<div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
						<form class="space-y-6" v-on:submit.prevent="processSubmission">
							<div>
								<label for="email" class="block text-sm font-medium text-gray-700">
									Tell Us What's Happening
								</label>
								<div class="mt-1">
									<wysiwyg v-model="ticket" />
								</div>
							</div>
							<div>
								<button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
									Send Ticket
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</template>
	</modal>
</template>

<script>
	export default {
		data() {
			return {
				ticket: ''
			}
		},
		methods: {
			processSubmission() {
				
			}
		}
	};
</script>