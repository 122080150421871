<template>
	<transition name="fade">
		<div id="vue-alert" >
			<div role="alert">
				<div class="text-white font-bold rounded-t px-4 py-2" :class="alertColor">
					{{ alert.title }}
				</div>
				<div class="border border-t-0 rounded-b px-4 py-3" :class="alertSecondaryColor">
					<p>{{ getMessage() }}</p>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
	export default {
		methods: {
			getMessage() {
				if(this.alert.text && this.alert.text !== '') {
					return this.alert.text
				}

				switch (this.alert.type) {
					case 'success':
						return 'It worked!'
						break;
					case 'error':
						return 'Something went wrong!'
						break;
					default:
						return 'Generic message!'
						break;
				}
			}
		},
		computed: {
			alert() {
				return this.$store.state.alert
			},
			alertColor() {
				let alert = this.$store.state.alert

				switch (alert.type) {
					case 'success':
						return 'bg-green-400'
						break;
					case 'error':
						return 'bg-red-500'
						break;
					default:
						return 'bg-blue-500'
						break;
				}
			},
			alertSecondaryColor() {
				let alert = this.$store.state.alert

				switch (alert.type) {
					case 'success':
						return 'border-green-400 bg-green-100 text-green-700'
						break;
					case 'error':
						return 'border-red-400 bg-red-100 text-red-700'
						break;
					default:
						return 'border-blue-400 bg-blue-100 text-blue-700'
						break;
				}
			}
		}
	};
</script>
<style lang="scss" scoped>
	#vue-alert {
		position: fixed;
		display: flex;
		flex-direction: row;
		justify-self: center;
		justify-content: center;
		top: 10px;
		width: 100%;
		z-index: 100000; /* Throw that jawn over everything */
	}
	.fade-enter-active, .fade-leave-active {
		transition: opacity .5s;
	}

	.fade-enter, .fade-leave-to {
		opacity: 0;
	}
</style>