<template>
	<thead>
		<tr>
			<th class="border-t px-2 py-2" scope="col">URL</th>
			<th class="border-t px-2 py-2" scope="col">Message</th>
			<th class="border-t px-2 py-2" scope="col">Context</th>
			<th class="border-t px-2 py-2" scope="col">Recommendations</th>
			<th class="border-t px-2 py-2" scope="col" style="max-width: 100px;"></th>
		</tr>
	</thead>
</template>

<script>
	export default {
	};
</script>