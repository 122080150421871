/* eslint-disable */
<template>
  <div id="app">
    <alert v-if="$store.state.showAlert"></alert>
    <confirm-modal></confirm-modal>
    <div class="h-screen flex overflow-hidden bg-gray-100">
      <property-mobile-nav></property-mobile-nav>
      <property-nav></property-nav>
      <div class="flex flex-col w-0 flex-1 overflow-hidden">
        <div class="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150" aria-label="Open sidebar" v-on:click="$store.dispatch('toggleMobileNav')">
            <svg class="h-6 w-6" fill="none" viewbox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>
        <property-dashboard />
        <login />
        <four-oh-four />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  mounted () {
    this.$store.dispatch('init')
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // margin-top: 60px;
}
</style>
