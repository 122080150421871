<template>
	<tr>
		<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-500">{{ topic }}</td>
		<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">{{ score }}%</td>
		<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">{{ cpc }}</td>
		<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">{{ volume }}</td>
		<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">{{ competition }}</td>
	</tr>
</template>

<script>
	export default {
		props: {
			inproperty: {

			},
			score: {

			},
			topic: {

			}
		},
		data() {
			return {
				cpc: null,
				volume: null,
				competition: null
			}
		},
		methods: {
			getPPC() {
				let ppc = this.inproperty.ppc,
					cpc,
					volume,
					competition;

				if(ppc) {
					ppc.forEach(p => {
						if(cpc) {
							return
						}

						if(p.keyword && p.keyword.toLowerCase() == this.topic.toLowerCase()) {
							cpc = p.cpc
							volume = p.volume
							competition = p.competition
						}
					})
				}

				this.cpc = cpc
				this.volume = volume
				this.competition = competition

				if(this.competition && this.competition !== '') {
					this.competition += '%'
				}

			}
		},
		mounted() {
			this.getPPC()
		}
	};
</script>
<style lang="scss" scoped>
</style>