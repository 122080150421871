<template>
	<div class="form-group">
		<h2>{{ title }}</h2>
		<!-- vdropzone-file-added(  file) -->
		<vue-dropzone
			ref="myVueDropzone"
			id="dropzone"
			:options="dropzoneOptions"
			v-on:vdropzone-success="setFiles"
		></vue-dropzone>
	</div
></template>

<script>
	import vue2Dropzone from 'vue2-dropzone'
	import 'vue2-dropzone/dist/vue2Dropzone.min.css'

	export default {
		components: {
			vueDropzone: vue2Dropzone
		},
		props: {
			title: {
				type: String,
				default: 'Upload'
			},
			url: {
				type: String,
				default: '/agency/upload'
			},
			maxfiles: {
				type: Number,
				default: 1
			}
		},
		data() {
			return {
				dropzoneOptions: {
					url: this.url,
					thumbnailWidth: 150,
					maxFilesize: 10,
					maxFiles: this.maxfiles,
					headers: { 
						'X-CSRF-TOKEN': window.csrfToken
					}
				}
			}
		},
		methods: {
			setFiles(files, response) {
				this.$emit('input', response)
			},
			clearAllFiles() {
				this.$refs.myVueDropzone.removeAllFiles()
			}
		}
	};
</script>