<template>
	<div>
		<nav class="flex flex-row justify-between">
			<h3 class="subtitle text-center mb-2 text-lg">
				{{ title }} View
			</h3>
			<!-- <button v-on:click="refreshScreenshot" class="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3 cursor-pointer text-white items-center">refresh</button> -->
		</nav>
		<div v-images-loaded:on.progress="imageProgress">
			<loading v-if="!isLoaded" />
			<img
				:src="imageUrl"
				:alt="alt"
				:title="alt"
			/>
		</div>
	</div>
</template>

<script>

	import imagesLoaded from 'vue-images-loaded'


	export default {
		directives: {
			imagesLoaded
		},
		props: {
			url: {
				required: true
			},
			isMobile: {
				type: Boolean,
				default: false
			}
		},
		watch: {
			url(n, o) {
				this.$forceUpdate()
			}
		},
		data() {
			return {
				isLoaded: false
			}
		},
		methods: {
			imageProgress(instance, image ) {
				this.isLoaded = image.isLoaded
			},
			onImgLoad () {
				this.isLoaded = true
			}
		},
		computed: {
			title() {
				return this.isMobile ? 'Mobile' : 'Desktop';
			},
			imageUrl() {
				let type = this.isMobile ? 'mobile' : 'desktop'

				return `${this.$store.state.apiUrl}/screenshot/${type}/url/${this.url}`
			},
			alt() {

				let type = this.isMobile ? 'Mobile' : 'Desktop';

				return `${type} screenshot of ${this.url}`

			}
		},
		mounted() {
		}
	};
</script>
<style lang="scss" scoped>
</style>