<template>
	<tbody>
		<template v-if="dnss && dnss.length">
			<ticket-table-row v-for="dns in dnss" :key="dns.id" :dns="dns" />
		</template>
		<tr v-else>
			<td colspan="6" class="text-center border-t px-2 py-2" scope="col">You don't have any active tickets!</td>
			</td>
		</tr>
	</tbody>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		methods: {
			
		},
		computed: {
			dnss() {
				return this.$store.state.property.dns
			}
		}
	};
</script>
<style lang="scss" scoped>
</style>