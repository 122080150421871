<template>
	<div class="w-full mb-2 flex flex-col">
		<div class="w-full mb-2 flex flex-row"">
			<input class="block appearance-none w-full bg-white border border-gray-400 text-black hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline mr-2" type="text" v-model="name" placeholder="Name" />
			<input class="block appearance-none w-full bg-white border border-gray-400 text-black hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline" type="text" v-model="message" placeholder="Message" />
		</div>
		<div class="w-full mb-2 flex flex-row"">
			<input class="block appearance-none w-full bg-white border border-gray-400 text-black hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline mr-2" type="email" v-model="email" placeholder="Email (leave blank to use your email)" />
			<button type="button" class="inline-block w-1/3 py-4 px-8 leading-none text-white bg-indigo-500 hover:bg-indigo-600 rounded shadow" v-on:click="addMonitor">Add</button>
		</div>
	</div>
</template>

<script>

	import {_v} from "@primitivesocial/clockwork"

	export default {
		data() {
			return {
				name: '',
				message: '',
				email: ''
			}
		},
		methods: {
			addMonitor() {

				if(!_v.required(this.name)) {
					this.$store.dispatch('makeAlert', {
						type: 'error',
						title: 'Uh Oh!',
						text: 'Please add a valid name'
					})

					return false;
				}

				if(!_v.required(this.message)) {
					this.$store.dispatch('makeAlert', {
						type: 'error',
						title: 'Uh Oh!',
						text: 'Please add a valid message'
					})

					return false;
				}

				if(this.email && !_v.email(this.email)) {
					this.$store.dispatch('makeAlert', {
						type: 'error',
						title: 'Uh Oh!',
						text: 'Please add a valid email'
					})

					return false;
				}

				this.$store.dispatch('addWebhook',
					{
						property: this.property.id,
						name: this.name,
						message: this.message,
						email: this.email
					})

				this.name = ''
				this.message = ''
				this.email = ''

			}
		},
		computed: {
			property() {
				return this.$store.state.property
			}
		}
	};
</script>
