/* eslint-disable */
<template>
  <main class="flex-1 relative z-0  focus:outline-none w-full max-w-7xl" tabindex="0">
    <div class="pt-2 pb-6 md:py-6" v-if="isVerified == 'verified' && isLoaded">
      <!-- max-w-7xl -->
      <div class="mx-auto px-4 sm:px-6 md:px-8">
        <h1 data-v-step="0" class="text-2xl font-semibold text-gray-900 flex flex-row">
          <img :src="'https://s2.googleusercontent.com/s2/favicons?domain=' + property.property_name + '&sz=32'" alt="Facebook Icon"/>&nbsp;&nbsp;{{ property.property_name }}
        </h1>
        <div class="flex">
          <h2 class="w-1/2 text-lg font-semibold text-gray-800">Last Crawled: {{ property.last_crawled ? property.last_crawled : 'Crawling now!' }}</h2>
          <h2 class="w-1/2 text-lg font-semibold text-gray-800">URLs Crawled: {{ getUrlCrawlCount }}</h2>
        </div>
      </div>
      <!-- max-w-7xl -->
      <div class="mx-auto px-4 sm:px-6 md:px-8">
        <div class="py-4">
          <div class="max-w-7xl">
            <ul class="flex items-end overflow-x-auto scrollbar">
              <li class="mr-1" v-on:click="toggleTab(1)" data-v-step="1">
                <button :class="openTab === 1 ? 'rounded-t text-blue-700 font-semibold' : 'text-blue-500 hover:text-blue-800 border-b'" class="bg-white border-l border-t border-r inline-block py-2 px-4 cursor-pointer text-sm md:text-xs font-semibold">Quick Look</button>
              </li>
              <li class="mr-1" v-on:click="toggleTab(2)" data-v-step="2">
                <button :class="openTab === 2 ? 'rounded-t text-blue-700 font-semibold' : 'text-blue-500 hover:text-blue-800 border-b'" class="bg-white border-l border-t border-r inline-block py-2 px-4 cursor-pointer text-sm md:text-xs font-semibold">SEO</button>
              </li>
              <li class="mr-1" v-on:click="toggleTab(3)">
                <button :class="openTab === 3 ? 'rounded-t text-blue-700 font-semibold' : 'text-blue-500 hover:text-blue-800 border-b'" class="bg-white border-l border-t border-r inline-block py-2 px-4 cursor-pointer text-sm md:text-xs font-semibold">Content</button>
              </li>
              <li class="mr-1" v-on:click="toggleTab(4)" data-v-step="3">
                <button :class="openTab === 4 ? 'rounded-t text-blue-700 font-semibold' : 'text-blue-500 hover:text-blue-800 border-b'" class="bg-white border-l border-t border-r inline-block py-2 px-4 cursor-pointer text-sm md:text-xs font-semibold">Site Speed</button>
              </li>
              <li class="mr-1" v-on:click="toggleTab(5)" data-v-step="4">
                <button :class="openTab === 5 ? 'rounded-t text-blue-700 font-semibold' : 'text-blue-500 hover:text-blue-800 border-b'" class="bg-white border-l border-t border-r inline-block py-2 px-4 cursor-pointer text-sm md:text-xs font-semibold">Uptime and SSL</button>
              </li>
              <li class="mr-1" v-on:click="toggleTab(6)" data-v-step="5">
                <button :class="openTab === 6 ? 'rounded-t text-blue-700 font-semibold' : 'text-blue-500 hover:text-blue-800 border-b'" class="bg-white border-l border-t border-r inline-block py-2 px-4 cursor-pointer text-sm md:text-xs font-semibold">DNS</button>
              </li>
              <li class="mr-1" v-on:click="toggleTab(7)" data-v-step="6">
                <button :class="openTab === 7 ? 'rounded-t text-blue-700 font-semibold' : 'text-blue-500 hover:text-blue-800 border-b'" class="bg-white border-l border-t border-r inline-block py-2 px-4 cursor-pointer text-sm md:text-xs font-semibold">Accessibility</button>
              </li>
              <li class="mr-1" v-on:click="toggleTab(8)" data-v-step="7">
                <button :class="openTab === 8 ? 'rounded-t text-blue-700 font-semibold' : 'text-blue-500 hover:text-blue-800 border-b'" class="bg-white border-l border-t border-r inline-block py-2 px-4 cursor-pointer text-sm md:text-xs font-semibold">Individual Urls</button>
              </li>
            </ul>
          </div>
          <div class="bg-white p-6 overflow-y-auto no-scrollbar" style="height:80vh">
            <div v-if="openTab === 1">
              <quick-view />
            </div>
            <div v-if="openTab === 2">
              <seo />
            </div>
            <div v-if="openTab === 3">
              <grammar-checks />
              <content-dashboard />
            </div>
            <div v-if="openTab === 4">
              <site-speed />
            </div>
            <div v-if="openTab === 5">
              <uptime-monitor />
            </div>
            <div v-if="openTab === 6">
              <dns />
            </div>
            <div v-if="openTab === 7">
              <accessibility />
            </div>
            <div v-if="openTab === 8">
              <individual-urls />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-2 pb-6 md:py-6" v-if="isVerified == 'not-verified'">
      <verify />
    </div>
    <loading v-if="!isLoaded" />
    <view-accessibility-details />
    <success />
  </main>
</template>

<script>
export default {
  props: [
    'starting-property'
  ],
  data () {
    return {
      openTab: 1,
      steps: [
        {
          target: '[data-v-step="0"]',
          header: {
            title: 'Get Started'
          },
          content: `Welcome to Hey, Check It! Let\'s take a look around.`
        },
        {
          target: '[data-v-step="1"]',
          content: 'Quick Look: Get overall stats on your site real quick',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '[data-v-step="2"]',
          content: 'SEO and Content: Overall on page SEO and content info',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '[data-v-step="3"]',
          content: 'Sitespeed: Take a look at how fast your site is performing',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '[data-v-step="4"]',
          content: 'Uptime: Set up monitors for your site, and make sure it\'s run running',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '[data-v-step="5"]',
          content: 'DNS: Monitor your DNS records',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '[data-v-step="6"]',
          content: 'Accessibility: Pointed WCAG feedback with tips on how to fix it',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '[data-v-step="7"]',
          content: 'Individual URLs: Get specific info on each of the URLs on your site',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '[data-v-step="8"]',
          content: 'Webhooks: Set up a webhook to get notified when something, just about anything, happens on your site',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '[data-v-step="9"]',
          content: 'Tools: A bunch of utilities for your site (we also throw beta features here)',
          params: {
            placement: 'bottom'
          }
        }
      ]
    }
  },
  methods: {
    toggleTab(tab) {
      this.openTab = tab
    }
  },
  computed: {
    property() {
      return this.$store.state.property
    },
    isLoaded() {
      return this.$store.state.isLoaded
    },
    isVerified() {
      return this.$store.state.propertyVerified
    },
    getUrlCrawlCount() {
      let urlCount = this.property.url_count,
        urlCrawledCount = this.property.urlcrawled_count;

      let percentage = Number(Math.round(urlCrawledCount / urlCount) * 100).toFixed(2);

      if(isNaN(percentage)) {
        percentage = 0
      }

      return `${urlCrawledCount}/${urlCount} (${percentage}%)`
    },
  },
  mounted() {
    if(this.shouldTour && this.shouldTour == 'yes') {
      setTimeout(f => this.$tours['freshStart'].start(), 3500)
    }

    if(this.startingProperty) {
      setTimeout(() => this.$store.dispatch('getProperty', this.startingProperty), 3000)
    }
  }
};
</script>
