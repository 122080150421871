<template>
	<div>
		<div class="flex flex-row flex-wrap flex-grow mt-2">
			<div class="w-full p-3">
				<!--Table Card-->
				<div class="bg-white border rounded shadow">
					<div class="border-b p-3">
						<h5 class="uppercase text-grey-dark text-2xl">Headings</h5>
						<p class="text-grey-darker text-base mb-3">These should contain your key words and phrases, as indicated below</p>
					</div>
					<div class="p-5">
						<table class="min-w-full">
							<thead>
								<tr>
									<th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Type
									</th>
									<th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Heading
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="h1 in h1s">
									<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
										h1
									</td>
									<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
										{{ h1 ? h1s : '_blank_' }}
									</td>
								</tr>
								<tr v-for="h2 in h2s">
									<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
										h2
									</td>
									<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
										{{ h2 ? h2 : '_blank_' }}
									</td>
								</tr>
								<tr v-for="h3 in h3s">
									<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
										h3
									</td>
									<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
										{{ h3 ? h3 : '_blank_' }}
									</td>
								</tr>
								<tr v-for="h4 in h4s">
									<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
										h4
									</td>
									<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
										{{ h4 ? h4 : '_blank_' }}
									</td>
								</tr>
								<tr v-for="h5 in h5s">
									<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
										h5
									</td>
									<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
										{{ h5 ? h5 : '_blank_' }}
									</td>
								</tr>
								<tr v-for="h6 in h6s">
									<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
										h6
									</td>
									<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
										{{ h6 ? h6 : '_blank_' }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<!--/table Card-->
			</div>
			
		</div>
		<!-- <url-key-phrases /> -->
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		methods: {

		},
		computed: {
			url() {
				return this.$store.state.propertyUrl
			},
			h1s() {
				return this.$store.state.propertyUrl?.h1s
			},
			h2s() {
				return this.$store.state.propertyUrl?.h2s
			},
			h3s() {
				return this.$store.state.propertyUrl?.h3s
			},
			h4s() {
				return this.$store.state.propertyUrl?.h4s
			},
			h5s() {
				return this.$store.state.propertyUrl?.h5s
			},
			h6s() {
				return this.$store.state.propertyUrl?.h6s
			},
			phrases() {
				return this.$store.state.propertyUrl?.key_phrases
			},
			topics() {
				return this.$store.state.propertyUrl?.topics
			}
		}
	};
</script>
