<template>
	<table class="w-full table-auto">
		<accessibility-table-header />
		<accessibility-table-body />
	</table>
</template>

<script>
	export default {
	};
</script>
