/* eslint-disable */
window._ = require('lodash');

window.axios = require('axios');
window.axios.withCredentials = true

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import Vue from 'vue'
import App from './App.vue'
import store from './store'

Vue.config.productionTip = false

import {VueClockwork} from "@primitivesocial/clockwork";
Vue.use(VueClockwork);

// Styles
import '@/assets/css/app.css'

// Vue Tour
import VueTour from 'vue-tour'
// require('vue-tour/dist/vue-tour.css')
Vue.use(VueTour)

const files = require.context('./components', true, /\.vue$/i)

files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.filter('titleFromSnake', function (value) {
	if (!value) return ''
	value = value.toString().split('_')
	let capitalized = value.map(function(word) {
		return word.charAt(0).toUpperCase() + word.substring(1, word.length);
	});
	return capitalized
})

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
