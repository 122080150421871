<template>
	<tr>
		<!-- url
		type
		description
		context
		location -->
	    <td class="border-t px-2 py-2">
	    	<badge :color="getColor">{{ accessibility.type }}</badge>
	    </td>
	    <td class="border-t px-2 py-2">{{ accessibility.url }}</td>
	    <td class="border-t px-2 py-2">{{ accessibility.description }}</td>
	    <td class="border-t px-2 py-2 cursor-pointer" v-on:click="dataToggle(accessibility)">Show Details</td>
	</tr>
</template>

<script>
	export default {
		props: {
			accessibility: {
				type: Object
			}
		},
		data() {
			return {
				
			}
		},
		methods: {
			dataToggle(accessibility) {
				this.$store.dispatch('showAccessibilityModal', accessibility)
			}
		},
		computed: {
			property() {
				return this.$store.state.property
			},
			getColor() {
				switch(this.accessibility.type) {
					case 'info':
						return 'bg-gray-500'
						break;
					case 'error':
						return 'bg-red-400'
						break;
					case 'warning':
						return 'bg-yellow-600'
						break;
				}

				return 'bg-gray-500'
			},
			getUrl() {
				return this.accessibility.url?.url
			}
		}
	};
</script>