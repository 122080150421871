<template>
	<div>
		<!-- <div v-if="property.sitespeed" v-html="property.sitespeed"></div> -->
		<p v-if="property.sitespeed" class="text-sm text-center">Powered by sitespeed.io</p>
		<iframe :src="sitespeed" class="w-full h-full" style="min-height: 700px" />
		<!-- <p v-if="!property.sitespeed">Sorry, not site speed information is available. We're running it now</p> -->
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		methods: {

		},
		computed: {
			property() {
				return this.$store.state.property
			},
			sitespeed() {
				return this.$store.state?.property?.sitespeed
			}
		}
	};
</script>