<template>
	<modal name="view-accessibility-details" :fullScreen="true">
		<h2 class="text-2xl font-bold" slot="modal-title">
			{{ accessibility.description }} <span v-if="help">{{ help }}. More info at: <a :href="helpUrl" target="_blank">{{ helpUrl }}</a></span>
			<badge :color="getColor">{{ accessibility.type }}</badge>
			<badge v-if="accessibility.impact" :color="getImpactColor">{{ accessibility.impact }}</badge>
		</h2>
		<div slot="modal-content" class="flex flex-wrap -mx-6 h-full">
			<div class="w-full px-6">
				<h4 class="text-lg mb-6">
					<a :href="accessibility.url" class="text-md leading-5 text-gray-500" :title="accessibility.description">{{ accessibility.url }}</a>
				</h4>
				<p v-if="tags" class="mb-2">Tags: {{ tags }}</p>
				<div class="m-2" v-html="getDetails"></div>
				<p class="text-sm leading-5 text-gray-500 mb-4">
					<pre><code>{{ accessibility.context }}</code></pre>
				</p>
			</div>
			<div class="w-full h-full px-6">
				<iframe :src="accessibility.url" width="100%" height="100%" />
			</div>
		</div>
	</modal>
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},
		methods: {

		},
		computed: {
			accessibility() {
				return this.$store.state.accessibility
			},
			getDetails() {

				let content = `<p class="mb-2"><strong>Description: ${this.accessibility.description}</strong></p>`
				content += `<p class="mb-2">Location: ${this.accessibility.location}</p>`

				return content

			},
			tags() {
				return this.$store.state.accessibility?.tags?.join(', ')
			},
			help() {
				return this.$store.state.help
			},
			helpUrl() {
				return this.$store.state.accessibility.helpUrl
			},
			getColor() {
				switch(this.accessibility.type) {
					case 'info':
						return 'bg-gray-500'
						break;
					case 'error':
						return 'bg-red-400'
						break;
					case 'warning':
						return 'bg-yellow-600'
						break;
				}

				return 'bg-gray-500'
			},
			getImpactColor() {
				switch(accessibility.impact) {
					case 'moderate':
						return 'bg-gray-500'
						break;
					case 'critical':
						return 'bg-red-400'
						break;
					case 'serious':
						return 'bg-yellow-600'
						break;
				}

				return 'bg-gray-500'
			}
		}
	};
</script>