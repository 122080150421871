<template>
	<span class="inline-block text-sm py-1 px-3 rounded-full text-white flex content-center items-center" :class="badgeColor">
		<slot></slot>
	</span>
</template>

<script>
	export default {
		components: {},
		props: {
			badgeType: {
				type: String,
				default: 'inactive'
			},
			color: {
				type: String
			}
		},
		computed: {
			badgeColor() {

				if(this.color) {
					return this.color
				}

				switch (this.badgeType) {
					case 'inactive':
						return 'bg-gray-400';
						break;
					case 'failure':
					case 'error':
						return 'bg-red-500';
						break;
					case 'success':
					case 'active':
						return 'bg-green-500';
						break;
					default:
						return 'bg-gray-400';
						break;
				}
			}
		}
	};
</script>