<template>
	<div class="w-full p-3">
		<div class="bg-white border rounded shadow">
			<div class="border-b p-3">
				<h5 class="uppercase text-grey-dark text-2xl">Spelling and Grammar Issues</h5>
			</div>
			<div class="p-5">
				<table class="w-full table-auto">
					<thead>
						<tr>
							<th class="border-t px-2 py-2" scope="col">Message</th>
							<th class="border-t px-2 py-2" scope="col">Context</th>
							<th class="border-t px-2 py-2" scope="col">Recommendations</th>
							<th class="border-t px-2 py-2" scope="col" style="max-width: 100px;"></th>
						</tr>
					</thead>
					<tbody>
						<template v-if="checks && checks.length">
							<tr
								v-for="check in checks"
								:key="check.id"
								:check="check"
							>
								<td class="border-t px-2 py-2">{{ check.message }}</td>
								<td class="border-t px-2 py-2">{{ check.context }}</td>
								<td class="border-t px-2 py-2">{{ check.recommendations }}</td>
								<td class="border-t px-2 py-2 cursor-pointer text-blue-800" v-on:click="markDismissed(check.id)">Dismiss</td>
							</tr>
						</template>
						<tr v-else>
							<td colspan="6" class="text-center border-t px-2 py-2" scope="col">
								There is no data to show here.
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				newlyDismissed: []
			}
		},
		methods: {
			markDismissed(id) {
				this.newlyDismissed.push(id)
				window.axios.post('/grammar/dismiss', {id:id})
			}
		},
		computed: {
			url() {
				return this.$store.state.propertyUrl
			},
			checks() {
				let checks = this.$store.state.propertyUrl.grammar_checks

				let output = [], check

				for(let checkId in checks) {
					check = checks[checkId]
					if(!check.dismissed && !this.newlyDismissed.includes(check.id)) {
						output.push(check)
					}
				}

				return output
			}
		}
	};
</script>
