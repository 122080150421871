<template>
	<div>
		<dns-table />
	</div>
</template>

<script>
	export default {
	};
</script>
