<template>
	<modal name="success-modal" :fullScreen="true">
		<h2 class="text-2xl font-bold" slot="modal-title">Let's GO!</h2>
		<div slot="modal-content" class="flex flex-wrap -mx-6 h-full">
			<div class="w-full px-6">
				<img src="https://media.giphy.com/media/1gqDQUaLe3mCc/giphy.gif" alt="Thumbs Up gif" />
			</div>
		</div>
	</modal>
</template>

<script>
	export default {
	};
</script>