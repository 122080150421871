<template>
	<tr>
	    <td class="border-t px-2 py-2">{{ dns.host }}</td>
	    <td class="border-t px-2 py-2">{{ dns.type }}</td>
	    <td class="border-t px-2 py-2">{{ dns.ttl }}</td>
	    <td class="border-t px-2 py-2">{{ dns.pretty_last_crawled }}</td>
	    <td class="border-t px-2 py-2 cursor-pointer" v-on:click="dataToggle = !dataToggle" v-if="!dataToggle">View Data</td>
	    <td class="border-t px-2 py-2" v-if="dataToggle">
			<div v-html="getDetails"></div>
			<a class="text-red-500 cursor-pointer" v-on:click="dataToggle = !dataToggle">Close</a>
		</td>
	</tr>
</template>

<script>
	export default {
		components: {},
		props: {
			dns: {
				type: Object
			}
		},
		data() {
			return {
				dataToggle: false
			}
		},
		computed: {
			property() {
				return this.$store.state.property
			},
			getDetails() {

				let lineData = this.dns.data,
					content = ''

				if(typeof lineData !== 'object') {
					lineData = JSON.parse(lineData)
				}

				for(const prop in lineData) {
					content += `<p><strong>${prop}</strong>: ${lineData[prop]}</p>`
				}

				return content

			}
		}
	};
</script>