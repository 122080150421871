<template>
	<thead>
		<tr>
			<th class="border-t px-2 py-2" scope="col">Host</th>
			<th class="border-t px-2 py-2" scope="col">Type</th>
			<th class="border-t px-2 py-2" scope="col">TTL</th>
			<th class="border-t px-2 py-2" scope="col">Last_Crawled</th>
			<th class="border-t px-2 py-2" scope="col">Info</th>
		</tr>
	</thead>
</template>

<script>
	export default {
	};
</script>