<template>
	<modal name="confirm-modal">
		<p class="text-2xl font-bold" slot="modal-title">Are You Sure?</p>
		<div slot="modal-content" class="flex flex-wrap -mx-6">
			<div class="w-full px-6">
				<p>This action cannot be undone.</p>
			</div>
		</div>
		<div slot="modal-actions" class="flex flex-row justify-end">
			<button class="inline-block w-full mx-1 py-2 px-3 leading-none text-white bg-teal-500 hover:bg-teal-600 rounded shadow" v-on:click="closeModal">Back To Safety</button>
			<button class="inline-block w-full mx-1 py-2 px-3 leading-none text-white bg-red-500 hover:bg-red-600 rounded shadow" v-on:click="runAction">Do It</button>
		</div>
	</modal>
</template>

<script>
	export default {
		data() {
			return {
				user: {}
			}
		},
		methods: {
			runAction() {
				let action = this.$store.state.confirmModal
				this.$store.dispatch(action.action, action.actionData)
				this.$store.dispatch('updateModals', 'confirm-modal')
			},
			closeModal() {
				this.$store.dispatch('updateModals', 'confirm-modal')
			}
		}
	};
</script>