<template>
	<tbody>
		<template v-if="accessibilities && accessibilities.length">
			<accessibility-table-row v-for="accessibility in accessibilities" :key="accessibility.id" :accessibility="accessibility" />
		</template>
		<tr v-else>
			<td colspan="6" class="text-center border-t px-2 py-2" scope="col">
				There is no data to show here.
			</td>
		</tr>
	</tbody>
</template>

<script>
	export default {
		computed: {
			accessibilitiesType() {
				return this.$store.state.accessibilitiesType
			},
			accessibilities() {
				let results = this.$store.state.property.accessibility?.results

				if(!results) {
					return []
				}

				let output = []

				for(let url in results) {

					let urlResults = results[url]

					for(let ruleIdx in urlResults.rules) {

						let rule = urlResults.rules[ruleIdx]

						output.push({
							url: url,
							type: rule.type,
							description: rule.description,
							context: rule.context,
							location: (rule.lastLine ? `${rule.lastLine} @ ${rule.firstColumn} - ${rule.lastColumn}` : 'n/a')
						})
					}
				}

				if(this.accessibilitiesType == 'all') {
					return output
				}

				return output.filter(o => o.type == this.accessibilitiesType)

			}
		}
	};
</script>
