<template>
	<div>
		<div v-if="!hasMonitors">
			<div class="w-full p-3">
				<!--Metric Card-->
				<div class="bg-white border rounded shadow p-2">
					<div class="flex flex-row items-center">
						<div class="flex-1 text-right md:text-center">
							<h5 class="uppercase text-grey">Just Getting Set up?</h5>
							<h3 class="text-3xl">Just hit Add to create your first monitor!</h3>
						</div>
					</div>
				</div>
				<!--/Metric Card-->
			</div>
		</div>
		<div class="w-full mb-2 flex flex-row justify-between">
			<div class="mt-1 flex rounded-md shadow-sm">
				<span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
					{{ propertyUrl }}
				</span>
				<input class="form-input flex-1 block w-full px-3 py-2 rounded-none rounded-r-md sm:text-sm sm:leading-5 shadow leading-tight focus:outline-none focus:shadow-outline" type="url" v-model="url" placeholder="URI for your site (i.e. /blog)" />
			</div>
			<button type="button" class="inline-block w-1/3 py-1 px-5 leading-none text-white bg-indigo-500 hover:bg-indigo-600 rounded shadow" v-on:click="addMonitor">Add</button>
		</div>
	</div>
</template>

<script>

	import {_v} from "@primitivesocial/clockwork"

	export default {
		data() {
			return {
				url: ''
			}
		},
		methods: {
			addMonitor() {

				let url = this.url

				if(! url.includes(this.propertyUrl)) {
					url = this.propertyUrl + '/' + this.url
				}

				if(!_v.url(url)) {
					this.$store.dispatch('makeAlert', {
						type: 'error',
						title: 'Uh Oh!',
						text: 'Please add a valid URL'
					})

					return false;
				}

				this.$store.dispatch('addMonitor',
					{
						url: url,
						property: this.property.id
					})
				this.url = ''
			}
		},
		computed: {
			property() {
				return this.$store.state.property
			},
			hasMonitors() {
				return this.$store.state.property?.monitors ? this.$store.state.property.monitors.length : false
			},
			propertyUrl() {
				return this.$store.state.property?.property_name
			}
		}
	};
</script>
