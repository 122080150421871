<template>
	<div class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-sm sm:w-full sm:p-6">
		<div>
			<div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
				<svg class="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
				</svg>
			</div>
			<div class="mt-3 text-center sm:mt-5">
				<h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">Your Site Meets All Standards!</h3>
				<div class="mt-2">
					<p class="text-sm leading-5 text-gray-500">Your site meets all WCAG A and AA standards, and has well formed HTML!</p>
				</div>
			</div>
		</div>
	 </div>
</template>

<script>
	export default {
	};
</script>