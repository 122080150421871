<template>
	<section class="section">
		<div class="container">
			<div class="flex flex-row flex-wrap flex-grow mt-2">
				<!-- <div class="w-full p-3">
					<div class="bg-white border rounded shadow">
						<div class="border-b p-3">
							<h5 class="uppercase text-grey-dark text-2xl">Key Phrases</h5>
							<p class="text-grey-darker text-base mb-3">These measure the primary phrases found throughout your site. Search engines use these terms in order to find out what your site is about, so they should ultimately match the topics you want covered so you can be found more easily, or convert pay-per-click ads better.</p>
						</div>
						<div class="p-5">
							<table class="w-full p-5 text-grey-darker">
								<thead>
									<tr>
										<th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Phrase</th>
										<th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Saturation</th>
										<th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">CPC</th>
										<th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Volume</th>
										<th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Competition</th>
									</tr>
								</thead>
								<tbody>
									<content-row v-for="(score, topic) in property.topics" :key="topic" :score="score" :topic="topic" :inproperty="property" />
								</tbody>
							</table>
						</div>
					</div>
				</div> -->
				<div class="w-full p-3">
					<!--Table Card-->
					<div class="bg-white border rounded shadow">
						<div class="border-b p-3">
							<h5 class="uppercase text-grey-dark text-2xl">Primary Topics</h5>
							<p class="text-grey-darker text-base mb-3">These measure the primary topics found throughout your site. Search engines use these terms in order to find out what your site is about, so they should ultimately match the topics you want covered so you can be found more easily, or convert pay-per-click ads better.</p>
						</div>
						<div class="p-5">
							<table class="w-full p-5 text-grey-darker">
								<thead>
									<tr>
										<th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Phrase</th>
										<th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Saturation</th>
										<th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">CPC</th>
										<th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Volume</th>
										<th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Competition</th>
									</tr>
								</thead>
								<tbody>
									<content-row v-for="(score, topic) in property.topics" :key="topic" :score="score" :topic="topic" :inproperty="property" />
								</tbody>
							</table>
						</div>
					</div>
					<!--/table Card-->
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		methods: {

		},
		computed: {
			property() {
				return this.$store.state.property
			}
		}
	};
</script>
