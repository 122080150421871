<template>
	<tr>
	    <td class="border-t px-2 py-2">{{ monitor.raw_url }}</td>
	    <td class="border-t px-2 py-2">
	    	<badge :badgeType="monitor.uptime_check_enabled ? 'active' : 'inactive'">{{ monitor.status }}</badge>
	    </td>
	    <td class="border-t px-2 py-2">{{ monitor.uptime_last_check_date ? monitor.uptime_last_check_date : 'Not run yet' }}</td>
	    <td class="border-t px-2 py-2">{{ monitor.certificate_expiration_date ? monitor.certificate_expiration_date : 'Not run yet' }}</td>
	    <td class="border-t px-2 py-2">{{ monitor.notification_type }}: {{ monitorData(monitor) }}</td>
		<td class="text-center border-t px-2 py-2">
	    	<button class="inline-block w-full mx-1 py-2 px-3 leading-none text-white text-xs bg-indigo-500 hover:bg-indigo-600 rounded shadow" v-on:click="toggleMonitor(monitor, 'uptime')">{{ getType(monitor, 'uptime') }} Uptime</button>
	    	<button class="inline-block w-full mx-1 py-2 px-3 leading-none text-white text-xs bg-indigo-500 hover:bg-indigo-600 rounded shadow" v-on:click="toggleMonitor(monitor, 'ssl')">{{ getType(monitor, 'ssl') }} SSL</button>
	    </td>
	</tr>
</template>

<script>
	export default {
		components: {},
		props: {
			monitor: {
				type: Object
			}
		},
		data() {
			return {

			}
		},
		methods: {
			viewMonitor() {
				this.$store.commit('setMonitor', this.monitor)
				this.$store.dispatch('updateModals', 'view-monitor-modal')
			},
			monitorData(monitor) {

				let data = JSON.parse(monitor.notification_data),
					type = monitor.notification_type

				switch(type) {
					case 'email':
				    	return data.email
				    	break;
					case 'slack':
				    	break;
					default:
				    	return 'unknown'
				}

			},
			getType(monitor, type) {
				if(type == 'uptime') {
					return monitor.uptime_check_enabled ? 'Disable' : 'Enable'
				}

				if(type == 'ssl') {
					return monitor.certificate_check_enabled ? 'Disable' : 'Enable'
				}
			},
			toggleMonitor(monitor, type) {

				let data = {
					monitor: monitor.id,
					type: type,
					property: this.property.id
				}

				this.$store.dispatch('toggleMonitor', data)
			}
		},
		computed: {
			property() {
				return this.$store.state.property
			}
		}
	};
</script>