/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

const store = new Vuex.Store({
	state: {
		apiUrl: null,
		agencyId: null,
		openMobileNav: false,
		properties: [],
		property: {},
		monitors: [],
		monitor: {},
		currentTab: '',
		isLoaded: false,
		modals: {
			'confirm-modal': false,
			'view-monitor-modal': false,
			'add-property-modal': false,
			'view-accessibility-details': false,
			'success-modal': false,
			'agency-new-user-modal': false,
			'agency-edit-user-modal': false,
			'login-modal': false,
			'404-modal': false
		},
		confirmModal: {
			action: '',
			actionData: {}
		},
		showAlert: false,
		alert: {
			type: '',
			title: '',
			text: ''
		},
		newProperty: {},
		coupon: {},
		user: {},
		propertyUrl: {},
		urlLoading: false,
		propertyVerified: false,
		accessibilities: [],
		accessibilitiesType: 'all',
		accessibility: {},
		dnss: [],
		webhooks: [],
		webhook: {},
		subscription: {},
		reportText: {
		  "IS_VALID_URL": {
		    "error": "The URL does not appear to be valid. Please double check to ensure the URL is valid.",
		    "success": "The URL appears valid"
		  },
		  "IS_REDIRECT": {
		    "error": "The URL received a 301 redirect to another URL.",
		    "success": "The URL received a 301 redirect to another URL."
		  },
		  "HAS_ONE_H1": {
		    "error": "Ensure your page has only one H1 tag. Multiple H1 tags can cause problems with your SEO.",
		    "success": "This page has one h1 tag"
		  },
		  "HAS_TITLE_TAG": {
		    "error": "Your page is missing a title tag. Add <title>Your page title</title> to your page",
		    "success": "This page has a title tag"
		  },
		  "HAS_META_DESCRIPTION": {
		    "error": "Your page is missing a meta description. Add <meta name=\"description\" value=\"Your description\" /> to your page.",
		    "success": "This page has a meta description"
		  },
		  "HAS_CANONICAL_TAG": {
		    "error": "Your page is missing a canonical link tag. Add <link rel=\"canonical\" href=\"yoururl\" /> to your page.",
		    "success": "This page has a canonical tag"
		  },
		  "HAS_JSON_LD": {
		    "error": "Your page is missing JSON-LD structured data, most search engine's preferred method of structured data.",
		    "success": "This page has structued data"
		  },
		  "IS_VALID_JSON_LD": {
		    "error": "Your page has JSON-LD, but it appears to be malformed or missing entirely.",
		    "success": "Your JSON-LD is valid"
		  },
		  "HAS_FAVICON": {
		    "error": "Your page is missing a favicon.",
		    "success": "This page has a favicon"
		  },
		  "WORD_COUNT": {
		    "error": "Your page is over 500 words, which is greater than most webpages.",
		    "success": "Your page is an appropriate length"
		  },
		  "SECONDS_TO_READ": {
		    "error": "Your page would take longer than normal for the average reader to read.",
		    "success": "Your page would take an appropriate amount of time to read"
		  },
		  "HAS_INTERNAL_LINKS": {
		    "error": "Your page doesn't appear to link to any other pages on your site. This also gets triggered if your page does not have fully canonical URLs in your A tags.",
		    "success": "Your page has internal links"
		  },
		  "ALL_IMAGES_HAVE_ALT_TAGS": {
		    "error": "Some of your images are missing ALT params on your page. This is an accessibility basic that feeds SEO.",
		    "success": "All images on this page have alt tags"
		  },
		  "SECONDS_TO_LOAD": {
		    "error": "Your page took over 300ms to load, which is longer than the average user is willing to wait.",
		    "success": "Your page loaded quick!"
		  },
		  "IMAGES_ARE_OPTIMIZED": {
		    "error": "",
		    "success": ""
		  },
		  "ONLY_ONE_TITLE_TAG": {
		    "error": "Your page has more than one title tag, which confuses search engines. Remove the extra title tag to fix this.",
		    "success": "Your page has one title tag."
		  },
		  "NETWORK_LATENCY_IN_SECONDS": {
		    "error": "Your page loads from your server at a slower than normal speed.",
		    "success": "Your page loads quickly from the server"
		  },
		  "REDIRECT_TIMING_IN_SECONDS": {
		    "error": "Your page took a longer than normal time to redirect.",
		    "success": "Your page redirected quickly"
		  },
		  "NAVIGATION_PAGE_LOAD_IN_SECONDS": {
		    "error": "Your page took a longer than normal time to load the entire page.",
		    "success": "Your page loaded the entire page in a timely manner"
		  },
		  "HAS_BROKEN_IMAGE": {
		    "error": "An image on the page is broken, causing a poor user experience.",
		    "success": "Your page had no broken images"
		  }
		},
		invoices: [],
		paymentMethods: [],
		token: null,
		is404: false,
		agency: {},
		tickets: []
	},
	getters: {
		apiUrl: state => apiUrl,
		agencyId: state => agencyId,
		openMobileNav: state => state.openMobileNav,
		properties: state => state.properties,
		property: state => state.property,
		monitors: state => state.monitors,
		monitor: state => state.monitor,
		currentTab: state => state.currentTab,
		isLoaded: state => state.isLoaded,
		modals: state => state.modals,
		confirmModal: state => state.confirmModal,
		showAlert: state => state.showAlert,
		alert: state => state.alert,
		newProperty: state => state.newProperty,
		coupon: state => state.coupon,
		user: state => state.user,
		propertyUrl: state => state.propertyUrl,
		urlLoading: state => state.urlLoading,
		propertyVerified: state => state.propertyVerified,
		accessibilities: state => state.accessibilities,
		accessibilitiesType: state => state.accessibilitiesType,
		accessibility: state => state.accessibility,
		dnss: state => state.dnss,
		webhooks: state => state.webhooks,
		webhook: state => state.webhook,
		subscription: state => state.subscription,
		reportText: state => state.reportText,
		invoices: state => state.invoices,
		paymentMethods: state => state.paymentMethods,
		token: state => state.token,
		is404: state => state.is404,
		agency: state => state.agency,
		tickets: state => state.tickets
	},
	mutations: {
		setApiUrl(state, apiUrl) {
			state.apiUrl = apiUrl
		},
		setAgencyId(state, agencyId) {
			state.agencyId = agencyId
		},
		setOpenMobileNav(state, openMobileNav) {
			state.openMobileNav = openMobileNav
		},
		setProperties(state, properties) {
			state.properties = properties
		},
		setProperty(state, property) {
			state.property = property
		},
		setMonitors(state, monitors) {
			state.monitors = monitors
		},
		setMonitor(state, monitor) {
			state.monitor = monitor
		},
		setIsLoaded(state, isLoaded) {
			state.isLoaded = isLoaded
		},
		setModals(state, modals) {
			state.modals = modals
		},
		setConfirmModal(state, confirmModal) {
			state.confirmModal = confirmModal
		},
		setShowAlert(state, showAlert) {
			state.showAlert = showAlert
		},
		setAlert(state, alert) {
			state.alert = alert
		},
		setNewProperty(state, newProperty) {
			state.newProperty = newProperty
		},
		setNewPropertyStep(state, data) {
			state.newProperty[data.key] = data.value
		},
		setCoupon(state, coupon) {
			state.coupon = coupon
		},
		setUser(state, user) {
			state.user = user
		},
		setPropertyUrl(state, propertyUrl) {
			state.propertyUrl = propertyUrl
		},
		setUrlLoading(state, urlLoading) {
			state.urlLoading = urlLoading
		},
		setPropertyVerified(state, propertyVerified) {
			state.propertyVerified = propertyVerified
		},
		setAccessibilities(state, accessibilities) {
			state.accessibilities = accessibilities
		},
		setAccessibilitiesType(state, accessibilitiesType) {
			state.accessibilitiesType = accessibilitiesType
		},
		setAccessibility(state, accessibility) {
			state.accessibility = accessibility
		},
		setDnss(state, dnss) {
			state.dnss = dnss
		},
		setWebhooks(state, webhooks) {
			state.webhooks = webhooks
		},
		setWebhook(state, webhook) {
			state.webhook = webhook
		},
		setSubscription(state, subscription) {
			state.subscription = subscription
		},
		setReportText(state, reportText) {
			state.reportText = reportText
		},
		setInvoices(state, invoices) {
			state.invoices = invoices
		},
		setPaymentMethods(state, paymentMethods) {
			state.paymentMethods = paymentMethods
		},
		setToken(state, token) {
			state.token = token
		},
		setIs404(state, is404) {
			state.is404 = is404
		},
		setAgency(state, agency) {
			state.agency = agency
		},
		setTickets(state, tickets) {
			state.tickets = tickets
		}
	},
	actions: {
		init(context) {
			// Set environment variables
			context.commit('setApiUrl', process.env.VUE_APP_API_URL)
			context.commit('setAgencyId', process.env.VUE_APP_AGENCY_ID)

			context.dispatch('getAgency')

			if(!context.state.token) {
				let token = localStorage.getItem('hci-token')

				if(!token) {
					context.dispatch('updateModals', 'login-modal')
				} else {
					context.commit('setToken', token)
					window.axios.defaults.headers.common = {
						'Authorization': `Bearer ${token}`
					}
				}
			}
		},
		getAgency(context) {
			window.axios.post(`${context.state.apiUrl}/agency`, { uuid: context.state.agencyId })
			.then(r => {
				let agency = r.data
				context.commit('setAgency', agency)
				context.dispatch('getProperties')
			})
			.catch(e => {
				context.dispatch('init')
			})
		},
		attemptLogin(context, creds) {
			creds.agencyId = context.state.agencyId
			window.axios.post(`${context.state.apiUrl}/agency/users/auth/login`, creds)
			.then(r => {
				const data = r.data.data
				let token = data.token
        localStorage.setItem('hci-token', token)

				context.commit('setToken', token)
				window.axios.defaults.headers.common = {
					'Authorization': `Bearer ${token}`
				}
				context.dispatch('getProperties')
				context.dispatch('getTickets')
			})
			.catch(e => {
				context.dispatch('makeAlert', {
					type: 'error',
					title: 'Uh Oh',
					text: 'Credentials did not match'
				})
			})
		},
		sendReset(context, email) {
			let creds = {
				agencyId: context.state.agencyId,
				email: email
			}
			window.axios.post(`${context.state.apiUrl}/password/reset`, creds)
			.then(r => {
				alert("Great! If your account exists, we sent you an email!")
			})
		},
		toggleMobileNav(context) {
			context.commit('setOpenMobileNav', !context.state.openMobileNav)
		},
		getPropertyUrl(context, id) {
			context.commit('setUrlLoading', true)
			window.axios.get(`${context.state.apiUrl}/url/${id}`)
			.then(r => {
				context.commit('setPropertyUrl', r.data)
				context.commit('setUrlLoading', false)
			})
		},
		getUser(context) {
			window.axios.get(`${context.state.apiUrl}/auth/me`)
			.then(r => {
				context.commit('setUser', r.data)
			})
		},
		getProperties(context, shouldOpenNew = false) {
			window.axios.get(`${context.state.apiUrl}/agency/properties`)
			.then((response) => {
				context.commit('setProperties', response.data);

				if(response.data && response.data.length && !context.state.property.id) {
					let propertyId = context.state.properties[0].id
					context.dispatch('getProperty', propertyId)
				} else {
					if(shouldOpenNew) {
						context.dispatch('showAddPropertyModal')
					}
				}
			})
		},
		getProperty(context, id = null) {

			if(!id) {
				id = context.state?.properties[0]?.id
			}

			context.commit('setIsLoaded', false)

			window.axios.get(`${context.state.apiUrl}/agency/properties/${id}`)
			.then((response) => {
			  let data = response.data.data
				context.commit('setProperty', data);
				context.commit('setPropertyVerified', (data.verified ? 'verified' : 'not-verified'))
				context.commit('setMonitors', data.monitors);
				context.commit('setDnss', data.dns);
				context.commit('setWebhooks', data.webhooks);
				context.commit('setSubscription', data.subscription)
				if(data.title) {
					document.title = `${data.title} | Hey, Check It`;
				} else if(data.name) {
					document.title = `${data.name} | Hey, Check It`;
				} else if(data.url) {
					document.title = `${data.url} | Hey, Check It`;
				} else {
					document.title = `Hey, Check It`;
				}

				context.commit('setIsLoaded', true)
			})
		},
		showAddPropertyModal(context) {
			context.state.modals['add-property-modal'] = true
		},
		showAccessibilityModal(context, accessibility) {
			context.commit('setAccessibility', accessibility)
			context.dispatch('updateModals', 'view-accessibility-details')
		},
		updateModals(context, key) {
			context.state.modals[key] = !context.state.modals[key]
		},
		showConfirmModal(context, data) {
			context.commit('setConfirmModal', data)
			context.dispatch('updateModals', 'confirm-modal')
		},
		closeAllModals(context) {
			let modals = context.state.modals
			for (const property in modals) {
				context.state.modals[property] = false
			}
		},
		makeAlert(context, data) {
			if(context.state.showAlert) {
				context.commit('setShowAlert', false)
			}

			context.commit('setAlert', data)
			context.commit('setShowAlert', true)

			setTimeout(function() {
				context.commit('setShowAlert', false)
			}, 2000)

		},
		getUserData(context) {
			window.axios.get('${context.state.apiUrl}/user-data')
			.then(r => {
				const data = r.data
				context.commit('setUser', data.user)
				context.commit('setProperties', data.properties)
				context.commit('setInvoices', data.invoices)
			})
		},
		acceptInvite(context, data) {
			window.axios.post(`${context.state.apiUrl}/auth/login`, creds)
			.then(r => {
				const data = r.data
				let token = data.token
				context.commit('setToken', token)
				window.axios.defaults.headers.common = {
					'Authorization': `Bearer ${token}`
				}
				context.dispatch('getProperties')
			})
			.catch(e => {
				context.dispatch('makeAlert', {
					type: 'error',
					title: 'Uh Oh',
					text: 'Credentials did not match'
				})
			})
		},
		checkIfInvite(context, data) {
			window.axios.post(`${context.state.apiUrl}/auth/login`)
			.then(r => {
				return r.data
			})
			.catch(e => {
				return false
			})
		},
		show404(context) {
			context.dispatch('closeAllModals')
			context.dispatch('updateModals', '404-modal')
		},
		getTickets(context, data) {
			window.axios.get(`${context.state.apiUrl}/agency/tickets`)
			.then(r => {
				let tickets = r.data
				context.commit('setTickets', tickets)
			})
		},
		addTicket(context, data) {
			window.axios.post(`${context.state.apiUrl}/agency/tickets`, data)
			.then(r => {
				let tickets = r.data
			})
		}
	}
})

export default store
