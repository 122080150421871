<template>
	<modal name="login-modal" v-if="!isLoaded" :force-open="true">
		<template v-slot:modal-title><h1>Let's Get Started...</h1></template>
		<template v-slot:modal-content>
			<div class="min-h-screen bg-gray-50 bg-opacity-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
				<div class="sm:mx-auto sm:w-full sm:max-w-md">
					<img class="mx-auto h-60 w-auto" :src="logoUrl" alt="Hey, Check It Logo" />
					<h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900" v-if="type == 'login'">
						Sign in to your account
					</h2>
					<h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900" v-if="type == 'forgot'">
						Reset Password
					</h2>
					<h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900" v-if="type == 'invite'">
						One More Step...
					</h2>
				</div>

				<div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
					<div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
						<form class="space-y-6" v-on:submit.prevent="processSubmission">
							<div>
								<label for="email" class="block text-sm font-medium text-gray-700">
									Email address
								</label>
								<div class="mt-1">
									<input v-model="email" type="email" autocomplete="email" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
								</div>
							</div>

							<div v-if="type == 'login'">
								<label for="password" class="block text-sm font-medium text-gray-700">
									Password
								</label>
								<div class="mt-1">
									<input v-model="password" type="password" autocomplete="current-password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
								</div>
							</div>

							<div class="flex items-center justify-between">
								<div class="text-sm" v-if="type == 'login'" v-on:click="toggleType">
									<a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
										Forgot your password?
									</a>
								</div>
								<div class="text-sm" v-if="type == 'forgot'" v-on:click="toggleType">
									<a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
										Sign In Instead...
									</a>
								</div>
							</div>

							<div v-if="type == 'login'">
								<button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
									Sign in
								</button>
							</div>
							<div v-if="type == 'forgot'">
								<button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
									Send Reminder
								</button>
							</div>
							<div v-if="type == 'invite'">
								<button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
									Let's Go
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</template>
	</modal>
</template>

<script>
	export default {
		data() {
			return {
				type: 'login',
				email: '',
				password: ''
			}
		},
		methods: {
			toggleType() {
				this.type = this.type === 'login' ? 'forgot' : 'login'
			},
			processSubmission() {
				if(this.type == 'login') {
					this.attemptLogin()
				}

				if(this.type == 'forgot') {
					this.sendReset()
				}

				if(this.type == 'invite') {
					this.acceptInvite()
				}
			},
			attemptLogin() {
				const creds = {
					email: this.email,
					password: this.password
				}

				this.$store.dispatch('attemptLogin', creds)
			},
			sendReset() {
				this.$store.dispatch('sendReset', {email: this.email})
			},
			acceptInvite() {
				const creds = {
					email: this.email,
					password: this.password
				}

				this.$store.dispatch('acceptInvite', creds)
			},
			processInvite() {
				// Check if it is an invite by code in query string
				const urlParams = new URLSearchParams(window.location.search)

				if(urlParams.has('code') && urlParams.has('email')) {
					this.type = 'invite'
					let data = {
						code: urlParams.get('code'),
						email: urlParams.get('email')
					}

					let result = this.$store.dispatch('checkIfInvite', data)
					// Get invite from App
					if(!result) {
						this.$store.dispatch('show404')
						return
					}

					this.email = urlParams.get('email')
				}
			}
		},
		computed: {
      isLoaded() {
        return this.$store.state.isLoaded
      },
			logoUrl() {
				return this.$store.state.agency.public_logo || 'https://app.heycheckit.com/img/hci-logo-dark.svg'
			}
		}
	};
</script>
