<template>
	<section>
		<add-webhook />
		<webhook-table />
	</section>
</template>

<script>
	export default {
	};
</script>