<template>
	<tbody>
		<template v-if="webhooks && webhooks.length">
			<webhook-table-row v-for="webhook in webhooks" :key="webhook.id" :webhook="webhook" />
		</template>
		<tr v-else>
			<td colspan="6" class="text-center border-t px-2 py-2" scope="col">You don't have any webhooks set up yet.</td>
			</td>
		</tr>
	</tbody>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		methods: {
			
		},
		computed: {
			webhooks() {
				return this.$store.state.property?.webhooks
			}
		}
	};
</script>