<template>
	<modal name="404-modal">
		<template v-slot:modal-title><h1>Uh Oh...</h1></template>
		<template v-slot:modal-content>
			<div class="min-h-screen bg-gray-50 bg-opacity-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
				<img src="https://media.giphy.com/media/L3pfVwbsJbrk4/giphy.gif" />
				<p>The page you are looking for isn't here.</p>
			</div>
		</template>
	</modal>
</template>

<script>
	export default {}
</script>