<template>
	<table class="w-full table-auto flex flex-row">
		<url-sidebar />
		<url-dashboard />
	</table>
</template>

<script>
	export default {
	};
</script>
