<template>
	<section>
		<div class="flex flex-wrap">
			<div class="w-full md:w-1/2 xl:w-1/3 p-3">
				<!--Metric Card-->
				<div class="bg-white border rounded shadow p-2">
					<div class="flex flex-row items-center">
						<div class="flex-1 text-right md:text-center">
							<h5 class="uppercase text-grey">Overall SEO Score</h5>
							<h3 class="text-3xl">{{ url.seo_score }}%</h3>
                            <small>Aim for 80+ on this</small>
						</div>
					</div>
				</div>
				<!--/Metric Card-->
			</div>
			<div class="w-full md:w-1/2 xl:w-1/3 p-3">
				<!--Metric Card-->
				<div class="bg-white border rounded shadow p-2">
					<div class="flex flex-row items-center">
						<div class="flex-1 text-right md:text-center">
							<h5 class="uppercase text-grey">Sentiment</h5>
							<h3 class="text-2xl">{{ url.sentiment }}: {{ url.sentiment_score }}</h3>
                            <small>Basic overall sentiment for your url</small>
						</div>
					</div>
				</div>
				<!--/Metric Card-->
			</div>
			<div class="w-full md:w-1/2 xl:w-1/3 p-3">
				<!--Metric Card-->
				<div class="bg-white border rounded shadow p-2">
					<div class="flex flex-row items-center">
						<div class="flex-1 text-right md:text-center">
							<h5 class="uppercase text-grey">Readability</h5>
							<h3 class="text-3xl">{{ url.readability }}</h3>
							<small>1-4: Rudimentary, 5-7: Average, 8+: Collegiate</small>
						</div>
					</div>
				</div>
				<!--/Metric Card-->
			</div>
		</div>
		<h2 class="subtitle text-center text-xl">Screenshots</h2>
		<div class="flex flex-col md:flex-row content-center">
			<div class="w-1/2 m:w-1/2 p-2">
				<url-screenshot :url="url.id" :is-mobile="true" />
			</div>
			<div class="w-1/2 m:w-1/2 p-2">
				<url-screenshot :url="url.id" :is-mobile="false" />
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		methods: {

		},
		computed: {
			url() {
				return this.$store.state.propertyUrl
			}
		},
		mounted() {
		}
	};
</script>
<style lang="scss" scoped>
</style>