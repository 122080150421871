<template>
	<table class="w-full table-auto">
		<grammar-check-table-header />
		<grammar-check-table-body />
	</table>
</template>

<script>
	export default {
		components: {},
		props: {},
		data() {
			return {
				docs: []
			}
		},
		methods: {

		},
		computed: {

		},
		mounted() {
		}
	};
</script>
<style lang="scss" scoped>
</style>