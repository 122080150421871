<template>
	<section class="section">
		<div class="flex flex-wrap">
			<div class="w-full p-3">
				<!--Metric Card-->
				<div class="bg-white border rounded shadow p-2">
					<div class="flex flex-row items-center">
						<div class="flex-1 text-right md:text-center">
							<h5 class="uppercase text-grey">Overall SEO Score</h5>
							<h3 class="text-3xl">{{ property.seo_score }}%</h3>
                            <small>Aim for 80+ on this</small>
						</div>
					</div>
				</div>
				<!--/Metric Card-->
			</div>
		</div>
		<div class="container">
			<div class="flex flex-row flex-wrap flex-grow mt-2">
				<div class="w-full p-3">
					<!--Table Card-->
					<div class="bg-white border rounded shadow">
						<div class="border-b p-3">
							<h5 class="uppercase text-grey-dark text-2xl">Backlinks</h5>
							<p class="text-grey-darker text-base mb-3">Backlinks, also called "inbound links" or "incoming links," are created when one website links to another. Backlinks are especially valuable for SEO because they represent a "vote of confidence" from one site to another.</p>
						</div>
						<div class="p-5">
							<table class="min-w-full">
								<thead>
									<tr>
										<th class="w-1/3 break-all whitespace-pre-line max-w-full px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Title</th>
										<th class="w-1/3 break-all whitespace-pre-line max-w-full px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Description</th>
										<th class="w-1/3 break-all whitespace-pre-line max-w-full px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">LastCrawled</th>
									</tr>
								</thead>
								<tbody>
									<tr valign="top" v-for="backlink in property.backlinks">
										<td class="w-1/3 break-all whitespace-pre-line max-w-full px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
											<a
												:href="backlink.link"
												:title="backlink.title"
												target="_blank">
												{{ backlink.title }}
											</a>
										</td>
										<td class="w-1/3 break-all whitespace-pre-line max-w-full px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
											{{ backlink.description }}
										</td>
										<td class="w-1/3 break-all whitespace-pre-line max-w-full px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
											{{ backlink.created_at }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<!--/table Card-->
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		methods: {

		},
		computed: {
			property() {
				return this.$store.state.property
			}
		},
		mounted() {
		}
	};
</script>
<style lang="scss" scoped>
</style>