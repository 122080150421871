<template>
	<tbody>
		<template v-if="checks && checks.length">
			<grammar-check-table-row
				v-for="check in checks"
				:key="check.id"
				:check="check"
				v-on:mark-dismissed="markDismissed"
			/>
		</template>
		<tr v-else>
			<td colspan="5" class="text-center border-t px-2 py-2" scope="col">
				There is no data to show here.
			</td>
		</tr>
	</tbody>
</template>

<script>
	export default {
		data() {
			return {
				newlyDismissed: []
			}
		},
		methods: {
			markDismissed(id) {
				this.newlyDismissed.push(id)
				window.axios.post('/grammar/dismiss', {id:id})
			}
		},
		computed: {
			checks() {
				let urls = this.$store.state.property.info

				if(!urls) {
					return []
				}

				let output = [],
					url, check

				for(let urlKey in urls) {
					url = urls[urlKey]
					let selectedUrl = url.url
					
					let checks = url.grammar_checks

					for(let checkId in checks) {
						check = checks[checkId]
						if(!check.dismissed && !this.newlyDismissed.includes(check.id)) {
							check.url = selectedUrl
							output.push(check)
						}
					}
				}

				return output

			}
		}
	};
</script>
