<template>
		<div class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-sm sm:w-full sm:p-6 m-auto">
		<div>
			<div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
				<svg class="text-red-600" width="150pt" height="150pt" viewBox="0 0 150 150" version="1.1">
					<g id="surface1">
						<path style=" stroke:none;fill-rule:nonzero;fill:rgb(88.627451%,29.803922%,29.411765%);fill-opacity:1;" d="M 148.203125 121.988281 L 87.28125 13.84375 C 82.085938 4.398438 67.917969 4.398438 62.722656 13.84375 L 1.804688 121.988281 C -3.390625 131.433594 3.222656 143.238281 14.082031 143.238281 L 135.449219 143.238281 C 146.785156 142.769531 153.394531 131.433594 148.203125 121.988281 Z M 148.203125 121.988281 "/>
						<path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 75.003906 20.925781 L 135.921875 129.070312 L 14.082031 129.070312 Z M 75.003906 20.925781 "/>
						<path style=" stroke:none;fill-rule:nonzero;fill:rgb(24.705882%,26.666667%,28.235294%);fill-opacity:1;" d="M 65.085938 70.515625 L 68.863281 95.542969 C 69.335938 98.375 71.699219 100.738281 75.003906 100.738281 C 77.835938 100.738281 80.671875 98.375 81.144531 95.542969 L 84.921875 70.515625 C 85.863281 64.375 81.144531 59.179688 75.003906 59.179688 C 68.863281 59.179688 64.613281 64.375 65.085938 70.515625 Z M 65.085938 70.515625 "/>
						<path style=" stroke:none;fill-rule:nonzero;fill:rgb(24.705882%,26.666667%,28.235294%);fill-opacity:1;" d="M 80.671875 111.597656 C 80.671875 114.730469 78.132812 117.265625 75.003906 117.265625 C 71.875 117.265625 69.335938 114.730469 69.335938 111.597656 C 69.335938 108.46875 71.875 105.933594 75.003906 105.933594 C 78.132812 105.933594 80.671875 108.46875 80.671875 111.597656 Z M 80.671875 111.597656 "/>
					</g>
				</svg>
			</div>
			<div class="mt-3 text-center sm:mt-5">
				<h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">There was a problem retrieving this URL.</h3>
				<div class="mt-2">
					<p class="text-sm leading-5 text-gray-500">{{ url.status }}</p>
				</div>
			</div>
		</div>
	 </div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		computed: {
			url() {
				return this.$store.state.propertyUrl
			}
		}
	};
</script>