<template>
	<div class="w-2/3">
		<loading v-if="loading" />
		<div v-if="!loading && !hasLoaded">
			<h2 class="subtitle text-center text-xl">Choose a URL to begin</h2>
		</div>
		<div v-if="!loading">
			<h2 class="subtitle text-center text-xl">{{ url.url }}</h2>
			<div class="overflow-y-auto scrollbar" style="height:75vh">
				<url-error v-if="isFailure" />
				<url-quick-view v-if="isSuccess" />
				<url-data v-if="isSuccess" />
				<url-grammar v-if="isSuccess" />
				<url-content v-if="isSuccess" />
				<url-accessibility v-if="isSuccess" :accessibility="this.url.accessibilty" />
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
			}
		},
		methods: {

		},
		computed: {
			loading() {
				return this.$store.state.urlLoading
			},
			url() {
				return this.$store.state.propertyUrl
			},
			isSuccess() {
				return this.$store.state.propertyUrl?.status_code == 200
			},
			isFailure() {
				return this.$store.state.propertyUrl && this.$store.state.propertyUrl.status_code && this.$store.state.propertyUrl.status_code != 200
			},
			hasLoaded() {
				return this.$store.state.propertyUrl.id ? true : false
			}
		}
	};
</script>
