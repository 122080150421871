<template>
	<tr>
	    <td class="border-t px-2 py-2">{{ webhook.name }}</td>
	    <td class="border-t px-2 py-2">
	    	<badge :badgeType="webhook.active ? 'active' : 'inactive'">{{ webhook.active ? 'active' : 'inactive' }}</badge>
	    </td>
	    <td class="border-t px-2 py-2 cursor-pointer" v-on:click="copyToClipboard(webhook.url)">{{ webhook.url }}</td>
		<td class="text-center border-t px-2 py-2">
	    	<button class="inline-block w-full mx-1 py-2 px-3 leading-none text-white text-xs bg-indigo-500 hover:bg-indigo-600 rounded shadow" v-on:click="togglewebhook(webhook)">{{ getType(webhook) }}</button>
	    </td>
	</tr>
</template>

<script>
	export default {
		props: {
			webhook: {
				type: Object
			}
		},
		data() {
			return {

			}
		},
		methods: {
			viewwebhook() {
				this.$store.commit('setwebhook', this.webhook)
				this.$store.dispatch('updateModals', 'view-webhook-modal')
			},
			webhookData(webhook) {

				let data = JSON.parse(webhook.notification_data),
					type = webhook.notification_type

				switch(type) {
					case 'email':
				    	return data.email
				    	break;
					case 'slack':
				    	break;
					default:
				    	return 'unknown'
				}

			},
			getType(webhook) {
				return webhook.active ? 'Disable' : 'Enable'
			},
			togglewebhook(webhook) {

				let data = {
					webhook: webhook.id,
					property: this.property.id
				}

				this.$store.dispatch('togglewebhook', data)
			},
			copyToClipboard(text) {

				let el = document.createElement('textarea')

				el.value = text

				el.setAttribute('readonly', '')
				
				el.style = {
					position: 'absolute',
					left: '-9999px'
				}
				
				document.body.appendChild(el)
				
				el.select()
				
				document.execCommand('copy')
				
				document.body.removeChild(el)

				this.$store.dispatch('makeAlert', {
					type: 'success',
					title: 'Copied!',
					text: 'Copied the URL!'
				})
			}
		},
		computed: {
			property() {
				return this.$store.state.property
			}
		}
	};
</script>