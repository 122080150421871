<template>
	<div class="w-1/3">
		<p class="group flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-900 rounded-md bg-gray-100 hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150">
			<span class="truncate" data-config-id="01_link1">URLs</span>
		</p>
		<nav class="overflow-x-hidden overflow-y-auto scrollbar" style="height:75vh">
			<a class="mt-1 group flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 transition ease-in-out duration-150 cursor-pointer" v-for="p in property.info" :key="p.id" v-on:click="setPropertyUrl(p.id)">
				<span class="truncate" :class="{'font-bold': url && url.id == p.id}">{{ p.url }}</span>
			</a>
		</nav>
	</div>
</template>

<script>
	export default {
		data() {
			return {
			}
		},
		methods: {
			setPropertyUrl(p) {
				this.$store.dispatch('getPropertyUrl', p)
			}
		},
		computed: {
			property() {
				return this.$store.state.property
			},
			url() {
				return this.$store.state.propertyUrl
			}
		}
	};
</script>