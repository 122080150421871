<template>
	<div class="w-full p-3">
		<div class="bg-white border rounded shadow">
			<div class="border-b p-3">
				<h5 class="uppercase text-grey-dark text-2xl">Spelling and Grammar Issues</h5>
			</div>
			<div class="p-5">
				<grammar-check-table />
			</div>
		</div>
	</div>
</template>

<script>
	export default {
	};
</script>