<template>
	<div>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {},
		data() {
			return {
				docs: []
			}
		},
		methods: {

		},
		computed: {

		},
		mounted() {
		}
	};
</script>
<style lang="scss" scoped>
</style>