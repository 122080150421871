<template>
	<div class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center modal-z-index" v-if="isOpen">
		<div class="modal-overlay absolute w-full h-full bg-gray-900" style="opacity:0.9;"></div>
		<div class="modal-container bg-white w-11/12 mx-auto rounded shadow-lg z-50 overflow-y-auto" v-bind:style="[ fullScreen ? {height: '90vh'} : '' ]">
			<div v-if="!forceOpen" class="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50" v-on:click="closeModal(name)">
				<svg class="fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
					<path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
				</svg>
				<span class="text-sm">(Esc)</span>
			</div>
			<!-- Add margin if you want to see some of the overlay behind the modal-->
			<div class="modal-content py-4 text-left px-6 h-full">
				<!--Title-->
				<div class="flex justify-between items-center pb-3">
					<slot name="modal-title"></slot>
					<div class="modal-close cursor-pointer z-50" v-on:click="closeModal(name)">
						<svg class="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
							<path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
						</svg>
					</div>
				</div>
				<slot name="modal-content"></slot>
				<!--Footer-->
				<div class="flex justify-end pt-2">
					<slot name="modal-actions"></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {
			name: {
				type: String,
				required: true
			},
			fullScreen: {
				type: Boolean,
				default: false
			},
			forceOpen: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				
			}
		},
		methods: {
			closeModal(name = null) {
				if(! name || name == this.name) {
					this.$store.dispatch('updateModals', this.name)
				}
			}
		},
		computed: {
			isOpen() {
				return this.$store.state.modals[this.name]
			}
		},
		created() {

		},
		beforeDestroy() {

		},
		mounted() {

			let self = this

			document.onkeydown = function(evt) {
				evt = evt || window.event
				let isEscape = false
				if ("key" in evt) {
					isEscape = (evt.key === "Escape" || evt.key === "Esc")
				} else {
					isEscape = (evt.keyCode === 27)
				}

				if (isEscape) {
					self.$store.dispatch('closeAllModals')
				}
			};
		}

	};
</script>
<style lang="scss" scoped>
.modal-z-index {
	z-index: 100;
}
</style>