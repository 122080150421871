<template>
	<tbody>
		<template v-if="monitors && monitors.length">
			<monitor-table-row v-for="monitor in monitors" :key="monitor.id" :monitor="monitor" />
		</template>
		<tr v-else>
			<td colspan="6" class="text-center border-t px-2 py-2" scope="col">You don't have any monitors set up yet.</td>
			</td>
		</tr>
	</tbody>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		methods: {
			
		},
		computed: {
			monitors() {
				return this.$store.state.property.monitors
			}
		}
	};
</script>
<style lang="scss" scoped>
</style>