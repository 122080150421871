<template>
	<div class="w-full mb-2 flex flex-col">
		<h3 class="text-3xl">Tools</h3>
		<p class="text-xl">These are just some general tools to help you with your site!</p>
		<!-- Public view -->
		<div class="border m-2 p-2 border-solid border-gray-400">			
			<p class="text-lg">Public link: <a :href="property.public_url" target="_blank" class="text-blue-900">{{ property.public_url }}</a></p>
			<p class="text-sm">This is a sharable link for you to show around!</p>
		</div>
		<button v-on:click="getSitemap" :disabled="sitemapLoading" class="bg-white text-center mb-3 border-l border-t border-r inline-block py-2 px-4 cursor-pointer font-semibold text-blue-500 hover:text-blue-800 border-b">{{ sitemapButtonText }}</button>
		<!-- <a :href="'/properties/' + property.id + '/settings'" target="_blank" class="bg-white text-center mb-3 border-l border-t border-r inline-block py-2 px-4 cursor-pointer font-semibold text-blue-500 hover:text-blue-800 border-b">Property Settings</a> -->
	</div>
</template>

<script>
	export default {
		data() {
			return {
				sitemapLoading: false
			}
		},
		methods: {
			getSitemap() {

				this.sitemapLoading = true
				window.axios.get(`/tools/${this.property.id}/sitemap`)
				.then(r => {

					this.sitemapLoading = false

					let sitemap = r.data

					window.open(sitemap, '_blank');

				})
				.catch(e => {
					this.$store.dispatch('makeAlert', {
						type: 'error',
						title: 'Uh Oh!',
						text: e.data
					})

					this.sitemapLoading = false
				})
			}
		},
		computed: {
			sitemapButtonText() {
				return this.sitemapLoading ? 'Building...stand by' : 'Get Sitemap'
			},
			property() {
				return this.$store.state.property
			}
		},
		mounted() {
		}
	};
</script>