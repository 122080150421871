<template>
	<thead>
		<tr>
			<th class="border-t px-2 py-2" scope="col">URL</th>
			<th class="border-t px-2 py-2" scope="col">Status</th>
			<th class="border-t px-2 py-2" scope="col">Last Uptime Check</th>
			<th class="border-t px-2 py-2" scope="col">Last SSL Check</th>
			<th class="border-t px-2 py-2" scope="col">Notifying</th>
			<th class="border-t px-2 py-2" scope="col">Actions</th>
		</tr>
	</thead>
</template>

<script>
	export default {
	};
</script>