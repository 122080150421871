<template>
	<div>
		<div class="flex flex-row flex-wrap flex-grow mt-2">
			<div class="w-full p-3">
				<!--Table Card-->
				<div class="bg-white border rounded shadow">
					<div class="border-b p-3">
						<h5 class="uppercase text-grey-dark text-2xl">Key Phrases</h5>
						<p class="text-grey-darker text-base mb-3">These measure the primary phrases found throughout your site. Search engines use these terms in order to find out what your site is about, so they should ultimately match the topics you want covered so you can be found more easily, or convert pay-per-click ads better.</p>
					</div>
					<div class="p-5">
						<table class="min-w-full">
							<thead>
								<tr>
									<th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Phrase
									</th>
									<th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Saturation
									</th>
									<!-- <th class="text-left text-blue-darkest">CPC</th>
									<th class="text-left text-blue-darkest">Volume</th>
									<th class="text-left text-blue-darkest">Competition</th> -->
								</tr>
							</thead>
							<tbody>
								<tr v-for="(phrase, idx) in phrases">
									<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
										{{ phrase.phrase }}
									</td>
									<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
										{{ phrase.score }}%
									</td>
									<!-- @php
										$count = false;
									@endphp
									@forelse($r['ppc'] as $ppc)
										@if(strtolower($ppc['keyword']) == strtolower($s['phrase']) && !$count)
											<td>{!! money_format('$%i', $ppc['cpc']) !!}</td>
											<td>{{ $ppc['volume'] }}</td>
											<td>{!! number_format($ppc['competition'], 2, '.', '') !!}%</td>
											@php
												$count = true;
											@endphp
										@endif
									@empty
									@endforelse -->
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<!--/table Card-->
			</div>
			
		</div>
		<div class="w-full p-3">
			<!--Table Card-->
			<div class="bg-white border rounded shadow">
				<div class="border-b p-3">
					<h5 class="uppercase text-grey-dark text-2xl">Primary Topics</h5>
					<p class="text-grey-darker text-base mb-3">These measure the primary topics found throughout your site. Search engines use these terms in order to find out what your site is about, so they should ultimately match the topics you want covered so you can be found more easily, or convert pay-per-click ads better.</p>
				</div>
				<div class="p-5">
					<table class="w-full p-5 text-grey-darker">
						<thead>
							<tr>
								<th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
									Phrase
								</th>
								<th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
									Saturation
								</th>
								<!-- <th class="text-left text-blue-darkest">CPC</th>
								<th class="text-left text-blue-darkest">Volume</th>
								<th class="text-left text-blue-darkest">Competition</th> -->
							</tr>
						</thead>
						<tbody>
							<tr v-for="topic in topics">
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
									{{ topic.label }}
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
									{{ topic.label }}%
								</td>
								<!-- @php
									$count = false;
								@endphp
								@forelse($r['ppc'] as $ppc)
									@if(strtolower($ppc['keyword']) == strtolower($t['label']) && !$count)
										<td>{!! money_format('$%i', $ppc['cpc']) !!}</td>
										<td>{{ $ppc['volume'] }}</td>
										<td>{!! number_format($ppc['competition'], 4, '.', '') !!}%</td>
										@php
											$count = true;
										@endphp
									@endif
								@empty
								@endforelse -->
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<!--/table Card-->
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		methods: {

		},
		computed: {
			url() {
				return this.$store.state.propertyUrl
			},
			phrases() {
				return this.$store.state.propertyUrl?.key_phrases
			},
			topics() {
				return this.$store.state.propertyUrl?.topics
			}
		}
	};
</script>
